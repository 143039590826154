<template lang="pug">
  .purchaseOrder
    project-facade(v-if="purchaseOrder", active-tab="purchaseOrder", :project-id="purchaseOrder.project.id")
    purchase-order-form(
      v-model='purchaseOrder',
      :loading='$apollo.loading',
      :saving='saving',
      submit-label='Update purchase order',
      @save='updatePurchaseOrder',
      @cancel='cancelUpdate')
</template>

<script>
import updatePurchaseOrderMutation from "@/graphql/mutations/finance/purchase_orders/UpdatePurchaseOrder.gql";
import updatePurchaseOrderFilter from "@/graphql/mutations/filters/UpdatePurchaseOrderFilter";
import purchaseOrderQuery from "@/graphql/queries/finance/PurchaseOrder.gql";
import mutate from "@/components/utils/Mutator";
import PurchaseOrderForm from "./Form.vue";
import ProjectFacade from "@/components/finance/project_facade/ProjectFacade.vue";

export default {
  apollo: {
    purchaseOrder: {
      query: purchaseOrderQuery,
      variables() {
        return { id: this.$route.params.id };
      }
    }
  },
  components: {
    PurchaseOrderForm,
    ProjectFacade
  },
  data() {
    return {
      saving: false,
      purchaseOrder: null
    };
  },
  methods: {
    updatePurchaseOrder() {
      const { project } = this.purchaseOrder;
      const vm = this;

      this.saving = true;

      return mutate(this, {
        mutation: updatePurchaseOrderMutation,
        variables: {
          input: {
            ...updatePurchaseOrderFilter(this.purchaseOrder)
          }
        }
      })
        .then(data => {
          window.location.assign(
            `/projects/${project.id}/purchase_orders/${data.data.updatePurchaseOrder.purchaseOrder.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    },
    cancelUpdate() {
      window.location.assign(
        `/projects/${this.purchaseOrder.project.id}/purchase_orders/${this.purchaseOrder.id}`
      );
    }
  }
};
</script>
